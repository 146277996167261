import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { RenderBlocks } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import { Icon } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { flattenToAppURL } from '@plone/volto/helpers';

const GridBlockView = (props) => {
  const { data, path, className } = props;
  const metadata = props.metadata || props.properties;
  const columns = data.blocks_layout.items;
  const isLrfPage = props?.content?.['@type'] === 'LRF';
  const blocksConfig =
    config.blocks.blocksConfig[data['@type']].blocksConfig ||
    props.blocksConfig;
  const location = {
    pathname: path,
  };

  let link = null;
  let href = data.linkHref?.[0]?.['@id'] || '';
  if (isInternalURL(href)) {
    link = (
      <UniversalLink href={flattenToAppURL(href)}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
        {data.linkTitle || href}
      </UniversalLink>
    );
  } else if (href) {
    link = (
      <UniversalLink href={href}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />{' '}
        {data.linkTitle || href}
      </UniversalLink>
    );
  }

  return (
    <div
      className={cx('block', data['@type'], className, {
        one: !isLrfPage && columns?.length === 1,
        two: !isLrfPage && columns?.length === 2,
        three: !isLrfPage && columns?.length === 3,
        four: !isLrfPage && columns?.length === 4,
        'full three': isLrfPage && columns?.length === 4,
        'full two': isLrfPage && columns?.length === 2,
      })}
    >
      <div className={isLrfPage && 'full-width'}>
        {(link || data.headline) && (
          <div className={'gridHeaderWrapper'}>
            {link && <div className="linkMoreRight">{link}</div>}
            {data.headline && <h2 className="headline">{data.headline}</h2>}
          </div>
        )}
        <Grid stackable stretched columns={columns.length}>
          <RenderBlocks
            {...props}
            blockWrapperTag={Grid.Column}
            metadata={metadata}
            content={data}
            location={location}
            blocksConfig={blocksConfig}
            isContainer
          />
        </Grid>
      </div>
    </div>
  );
};

export default withBlockExtensions(GridBlockView);
