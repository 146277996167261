import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Icon } from '@plone/volto/components';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import envelopSVG from 'volto-ipn/icons/envelope-regular.svg';
import phoneSVG from 'volto-ipn/icons/phone-solid.svg';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { isInternalURL } from '@plone/volto/helpers';
import { MaybeWrap } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import config from '@plone/volto/registry';
import './PersonBody.less';
import userSVG from '@plone/volto/icons/user.svg';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserPersonTemplate = (props) => {
  const { className, data, isEditMode } = props;
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  const HeadlineTag = data?.headlineTag || 'h2';

  const Image = config.getComponent('Image').component;
  const { openExternalLinkInNewTab } = config.settings;
  const target_url =
    href && (data?.externalURL ? data?.externalURL : href && href?.['@id']);

  return (
    <div className={cx('block teaser', className)}>
      <>
        {!href && isEditMode && (
          <Message>
            <div className="teaser-item placeholder">
              <img src={imageBlockSVG} alt="" />
              <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
            </div>
          </Message>
        )}
        {target_url && (
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            href={target_url}
            target={
              data.openLinkInNewTab ||
              (openExternalLinkInNewTab && !isInternalURL(target_url))
                ? '_blank'
                : null
            }
          >
            <div className="teaser-item default person two wide column">
              {!data.hideImage && (
                <div className="image-wrapper">
                  {href.hasPreviewImage || href.image_field || image ? (
                    <Image
                      item={image || href}
                      imageField={image ? image.image_field : href.image_field}
                      alt="Portrait"
                      loading="lazy"
                      responsive={true}
                    />
                  ) : (
                    <Icon name={userSVG} size="130px" />
                  )}
                </div>
              )}
              <div className="content">
                {data?.head_title && (
                  <div className="headline">{data.head_title}</div>
                )}
                {href?.academic_degree && (
                  <div className="headline">{href.academic_degree}</div>
                )}
                <HeadlineTag>{data?.title}</HeadlineTag>
                {data?.showPersonDetails && (
                  <>
                    <div className={'org-unit'}>
                      {href?.org_units?.sort?.()?.map?.((org_unit) => (
                        <p className={'org-unit'}>{org_unit}</p>
                      ))}
                    </div>
                    {(href.has_email_address || href?.email_addresses?.[0]) && (
                      <div className={'email'}>
                        <Icon
                          color="#003F7D"
                          name={envelopSVG}
                          size="18px"
                          title="Location"
                        />
                        <p>
                          <FormattedMessage
                            id="see_profile"
                            defaultMessage="see profile"
                          />
                        </p>
                      </div>
                    )}
                    {href?.phone_numbers?.[0] && (
                      <div className={'phone'}>
                        <Icon
                          color="#003F7D"
                          name={phoneSVG}
                          size="18px"
                          title="Location"
                        />
                        <p>{href.phone_numbers[0]}</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </MaybeWrap>
        )}
      </>
    </div>
  );
};

TeaserPersonTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserPersonTemplate;
