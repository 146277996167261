import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { Icon, MaybeWrap, UniversalLink } from '@plone/volto/components';
import { Input, Button, Message } from 'semantic-ui-react';
import cx from 'classnames';
import navTreeSVG from '@plone/volto/icons/nav.svg';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import config from '@plone/volto/registry';
import Caption from 'volto-ipn/components/Caption/Caption';

import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import { useNodeDimensions } from '@kitconcept/volto-slider-block/helpers';

import './slider-frontpage.less';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
  moreInfo: {
    id: 'moreInfo',
    defaultMessage: 'More info',
  },
  source: {
    id: 'Source',
    defaultMessage: 'Source',
  },
});

const SliderBody = ({
  index,
  onChangeBlock,
  block,
  data,
  dataBlock,
  isEditMode,
  openObjectBrowser,
}) => {
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  const Image = config.getComponent({ name: 'Image' }).component;

  const [sliderNode, setSliderNode] = React.useState(null);

  const updateSliderNode = () => {
    setSliderNode(document.querySelector('.block.slider .slick-active'));
  };

  React.useEffect(() => {
    updateSliderNode();
    window.addEventListener('resize', updateSliderNode);

    return () => {
      window.removeEventListener('resize', updateSliderNode);
    };
  }, []);

  const { width } = useNodeDimensions(sliderNode);

  const handleClick = () => {
    openObjectBrowser({
      onSelectItem: (url, document) => {
        dataBlock.slides[index].title = document.Title;
        dataBlock.slides[index].description = document.Description;
        dataBlock.slides[index].href = [
          {
            '@id': document['@id'],
            Title: document.Title,
            Description: document.Description,
            title: document.Title,
            image_field: document.image_field,
            hasPreviewImage: document.hasPreviewImage,
          },
        ];
        onChangeBlock(block, dataBlock);
      },
      mode: 'link',
    });
  };

  return (
    <div
      className={cx('grid-teaser-item top', {
        'empty-slide': !href && isEditMode,
      })}
    >
      {!href && isEditMode && (
        <Message>
          <div className="grid-teaser-item default">
            <img src={imageBlockSVG} alt="" />
            <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
            <div className="toolbar-inner">
              <Button.Group>
                <Button onClick={handleClick} icon basic>
                  <Icon name={navTreeSVG} size="24px" />
                </Button>
              </Button.Group>
              <Input
                placeholder={`${intl.formatMessage(messages.source)}...`}
                onClick={handleClick}
                onFocus={(e) => e.target.blur()}
              />
            </div>
          </div>
        </Message>
      )}
      {href && (
        <div className="teaser-item frontpage top">
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            href={href['@id']}
            target={data.openLinkInNewTab ? '_blank' : null}
            tabIndex="-1"
          >
            {(href?.hasPreviewImage || image) && (
              <div className="highlight-image-wrapper gradient">
                <Image
                  item={image}
                  imageField="image"
                  responsive={true}
                  alt={data.alt}
                />
                <Caption credit={image ? image.rights : href.rights} />
              </div>
            )}
            <div
              className="teaser-item-title fix-width-issue"
              style={{ width: width - 50 + 'px' }}
            >
              <div className="title">
                {data?.head_title && (
                  <span className="supertitle">{data?.head_title}</span>
                )}
                {data?.title && (
                  <Button className={'ipn'}>
                    {data?.nav_title || data?.title}
                    <Icon name={chevronSVG} color="#fff" size="19px" />
                  </Button>
                )}
              </div>
            </div>
          </MaybeWrap>
        </div>
      )}
    </div>
  );
};

export default SliderBody;
