export const Schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['text', 'name'],
      },
    ],
    properties: {
      text: {
        title: 'Text',
        widget: 'text',
        default: 'Zitat',
      },
      name: {
        title: 'Name',
        widget: 'text',
      },
    },
  };
};

export default Schema;
