import React from 'react';
import LargeHeroBody from './Body';
import { withBlockExtensions } from '@plone/volto/helpers';
import './styles.less';

const LargeHeroView = (props) => {
  return <LargeHeroBody {...props} />;
};

export default withBlockExtensions(LargeHeroView);
