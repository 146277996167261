/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React from 'react';
import { Container, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Logo, Navigation } from '@plone/volto/components';
import HeaderTop from 'volto-ipn/components/Theme/Header/HeaderTop';
import SearchWidget from './SearchWidget';
import { flattenToAppURL } from '@plone/volto/helpers';
import useSticky from './useSticky';
import StickyLogo from '../Logo/StickyLogo';

import './Header.less';

/**
 * Header component class.
 * @class Header
 * @extends Component
 */
const Header = (pathname) => {
  const { content } = useSelector(
    (state) => ({
      content: state.content?.data,
    }),
    shallowEqual,
  );
  const { sticky, stickyRef } = useSticky();
  const portal_type = content?.['@type'];

  const download =
    content?.preview_image?.scales?.huge?.download ||
    content?.image?.scales?.huge?.download;
  const show_image = download && ['Document', 'Event'].includes(portal_type);

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  return (
    <>
      <div
        ref={stickyRef}
        className={classNames('header-sticky-wrapper', { sticky })}
      >
        <Segment basic className="header-wrapper" role="banner">
          <Container>
            <div className="header">
              <HeaderTop />
              <div className="logo-nav-wrapper">
                <div className={!sticky ? 'big logo' : 'logo'}>
                  <Logo />
                </div>
                <div className={sticky ? 'small logo' : 'logo'}>
                  <StickyLogo />
                </div>
                <Navigation pathname={pathname.pathname} />
                <SearchWidget />
              </div>
            </div>
          </Container>
        </Segment>
      </div>
      {show_image && download && (
        <div className="block full">
          <img
            className="full-width large bannerimage"
            src={flattenToAppURL(download)}
            alt={content.alttext || ''}
            loading="lazy"
          />
        </div>
      )}
    </>
  );
};

export default Header;

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
Header.defaultProps = {
  token: null,
  content: null,
};
