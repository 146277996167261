import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import ConditionalDate from 'volto-ipn/components/ConditionalDate/ConditionalDate';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import documentSVG from 'volto-ipn/icons/document.svg';
import fileSVG from '@plone/volto/icons/file.svg';
import folderSVG from '@plone/volto/icons/folder.svg';
import eventSVG from 'volto-ipn/icons/event.svg';
import linkSVG from 'volto-ipn/icons/link.svg';
import imageSVG from 'volto-ipn/icons/image.svg';
import userSVG from 'volto-ipn/icons/person.svg';
// import groupSVG from '@plone/volto/icons/group.svg';
import bookSVG from 'volto-ipn/icons/book.svg';
import newspaperSVG from 'volto-ipn/icons/newspaper.svg';
import pdfSVG from 'volto-ipn/icons/file-pdf-regular.svg';
import flaskSVG from 'volto-ipn/icons/flask.svg';
import diagramprojectSVG from 'volto-ipn/icons/diagram-project.svg';
import griplinesSVG from 'volto-ipn/icons/grip-lines.svg';

const SearchResultsListingBlockTemplate = ({
  headlineTag,
  items,
  linkTitle,
  linkHref,
  isEditMode,
  showDate,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  const getTitleTag = (tag) => {
    const level = tag.slice(-1);
    if (/\d/.test(level)) {
      return `h${Number(level) + 1}`;
    } else {
      return 'h3';
    }
  };
  const TitleTag = headlineTag ? getTitleTag(headlineTag) : 'h3';

  const truncate = (str, maxlength) => {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  };

  const type_mapping = {
    Document: documentSVG,
    'News Item': newspaperSVG,
    Folder: folderSVG,
    File: fileSVG,
    Image: imageSVG,
    Event: eventSVG,
    Link: linkSVG,
    person: userSVG,
    publication: bookSVG,
    project: flaskSVG,
    organization: diagramprojectSVG,
    researchline: griplinesSVG,
  };
  const portal_type_icon = (item) => {
    if (item['@type'] === 'File' && item.mime_type === 'application/pdf') {
      return pdfSVG;
    }
    return type_mapping[item['@type']];
  };

  return (
    <>
      <div className="items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <ConditionalLink
              to={item?.['@id'] + '?show_navhelper=1'}
              condition={!isEditMode}
            >
              <div className="listing-body">
                {showDate && <ConditionalDate item={item} />}
                <TitleTag>
                  <span className="contentTypeInlineLabelWrapper">
                    <Icon
                      className="contentTypeInlineLabel"
                      name={portal_type_icon(item) || documentSVG}
                      size="32px"
                    />
                  </span>
                  {item.title || item.id}
                </TitleTag>
                <p>{truncate(item.description, 300)}</p>
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>

      {link && (
        <div className="footer">
          <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
          {link}
        </div>
      )}
    </>
  );
};
SearchResultsListingBlockTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default SearchResultsListingBlockTemplate;
