import React from 'react';
import { Message } from 'semantic-ui-react';
import Slider from 'react-slick';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import Body from '@kitconcept/volto-slider-block/components/Body';
import { withBlockExtensions } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import teaserTemplate from '@kitconcept/volto-slider-block/icons/teaser-template.svg';
import {
  SlidesWidthFix,
  useNodeDimensions,
} from '@kitconcept/volto-slider-block/helpers';
import config from '@plone/volto/registry';
import pauseSVG from '@plone/volto/icons/pause.svg';
import playSVG from '@plone/volto/icons/play.svg';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
const SliderView = (props) => {
  const {
    className,
    data,
    isEditMode,
    block,
    openObjectBrowser,
    onChangeBlock,
    slideIndex,
    setSlideIndex,
  } = props;
  const intl = useIntl();

  const sliderRef = React.useRef();
  const isDefaultVariant = data.variation === 'default';

  if (sliderRef.current && isEditMode) {
    // This syncs the current slide with the objectwidget (or other sources
    // able to access the slider context)
    // that can modify the SliderContext (and come here via props slideIndex)
    sliderRef.current.slickGoTo(slideIndex);
  }

  const [headerNode, setHeaderNode] = React.useState(null);

  React.useEffect(() => {
    setHeaderNode(
      document.querySelector(
        config.blocks.blocksConfig.slider.referenceContainerQuery,
      ),
    );
  }, []);
  const { width } = useNodeDimensions(headerNode);

  const [playing, setPlaying] = React.useState(true);
  const togglePlay = () => {
    if (playing) {
      sliderRef.current.slickPause();
      setPlaying(false);
    } else {
      sliderRef.current.slickPlay();
      setPlaying(true);
    }
  };

  const appendDots = (dots) => {
    return (
      <ul>
        {dots}
        <li onClick={togglePlay} aria-hidden="true">
          <button className="pause">
            <Icon
              className="pause"
              name={playing ? pauseSVG : playSVG}
              size="30px"
            />
          </button>
        </li>
      </ul>
    );
  };

  return (
    <>
      <SlidesWidthFix width={width} />
      <div
        className={cx('block slider', { full: isDefaultVariant }, className)}
      >
        <ConditionalWrapper
          condition={isDefaultVariant}
          wrapper={(children) => <div className={'full-width'}>{children}</div>}
        >
          {(data.slides?.length === 0 || !data.slides) && isEditMode && (
            <Message>
              <div className="teaser-item default">
                <img src={teaserTemplate} alt="" />
                <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
              </div>
            </Message>
          )}
          {data.slides?.length > 0 && (
            <Slider
              ref={sliderRef}
              dots
              infinite
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              draggable={false}
              slideWidth="1200px"
              appendDots={appendDots}
              autoplay={!isEditMode ? true : false}
              autoplaySpeed={5000}
              // This syncs the current slide with the SliderContext state
              // responding to the slide change event from the slider itself
              // (the dots or the arrows)
              // There's also the option of doing it before instead than after:
              // beforeChange={(current, next) => setSlideIndex(next)}
              afterChange={(current) => isEditMode && setSlideIndex(current)}
            >
              {data.slides &&
                data.slides.map((item, index) => (
                  <div key={item['@id']}>
                    <Body
                      {...props}
                      key={item['@id']}
                      data={item}
                      isEditMode={isEditMode}
                      dataBlock={data}
                      index={index}
                      block={block}
                      openObjectBrowser={openObjectBrowser}
                      onChangeBlock={onChangeBlock}
                    />
                  </div>
                ))}
            </Slider>
          )}
        </ConditionalWrapper>
      </div>
    </>
  );
};

export default withBlockExtensions(SliderView);
