import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { Container, Table } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import locationSVG from 'volto-ipn/icons/location-dot-solid.svg';
import envelopSVG from 'volto-ipn/icons/envelope-regular.svg';
import phoneSVG from 'volto-ipn/icons/phone-solid.svg';
import { UniversalLink } from '@plone/volto/components';
import { Grid } from 'semantic-ui-react';
import { decodeEmail } from 'volto-ipn/helpers';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import orcidIdSVG from 'volto-ipn/icons/orcid-id.svg';

import './Person.less';

const PersonView = (props) => {
  const { content } = props;
  const subheading_parts = [];
  if (content?.academic_degree) {
    subheading_parts.push(content.academic_degree);
  }
  const [all_projects, set_all_projects] = useState(false);

  const org_units = content?.org_units?.sort?.((a, b) => a.title > b.title);
  // eslint-disable-next-line no-unused-expressions
  org_units?.forEach?.((el) =>
    subheading_parts.push(
      el.href ? (
        <UniversalLink href={el.href}>{el.title}</UniversalLink>
      ) : (
        el.title
      ),
    ),
  );
  const subheading = subheading_parts.length > 0;

  return (
    <Container className="view-wrapper person-view">
      <h1 className="documentFirstHeading">
        {content.first_name} {content.last_name}
      </h1>
      <div className="person-details">
        {subheading && (
          <h3>
            {subheading_parts.map((item, i) => (
              <>
                {i > 0 && ' | '}
                {item}
              </>
            ))}
          </h3>
        )}
        {content?.orcid && (
          <p className={'orcid'}>
            <Icon name={orcidIdSVG} title="ORCID:" size="22px" />{' '}
            <UniversalLink href={'https://orcid.org/' + content.orcid}>
              {content.orcid}
            </UniversalLink>
          </p>
        )}
        <Grid stackable columns={2}>
          <Grid.Row>
            {content?.image?.scales?.mini?.download && (
              <Grid.Column width={2}>
                <div className="image-wrapper">
                  <img
                    alt={content.title}
                    src={flattenToAppURL(content.image.scales.mini.download)}
                    loading="lazy"
                  />
                </div>
              </Grid.Column>
            )}
            <Grid.Column width={10}>
              <>
                {content?.visiting_address && (
                  <h3 className={'icon-heading'}>
                    <Icon
                      name={locationSVG}
                      size="22px"
                      title="Location"
                      style={{ paddingRight: '15px' }}
                    />
                    {content.visiting_address}
                  </h3>
                )}
                {content?.email_addresses?.[0] && (
                  <div>
                    {content?.email_addresses?.map?.((item) => {
                      item = decodeEmail(item);
                      return (
                        <h3 className={'icon-heading'}>
                          <Icon name={envelopSVG} size="22px" title="Mail" />
                          <a href={`mailto:${item}`}>{item}</a>
                        </h3>
                      );
                    })}
                  </div>
                )}
                {content?.phone_numbers?.[0] && (
                  <>
                    {content?.phone_numbers?.map?.((item) => (
                      <h3 className={'icon-heading'}>
                        <Icon name={phoneSVG} size="22px" title="Phone" />
                        {item}
                      </h3>
                    ))}
                  </>
                )}
              </>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <hr className={'blue'} />
      {content?.current_projects?.[0] && (
        <Container className={'person-listing current-projects'}>
          <h2>
            <FormattedMessage
              id="current_projects"
              defaultMessage="Current projects"
            />
          </h2>
          <ul>
            {(all_projects
              ? content.current_projects
              : content.current_projects.slice(0, 3)
            ).map((pr) => (
              <li>
                <UniversalLink href={pr.href}>{pr.title}</UniversalLink>
              </li>
            ))}
          </ul>
          {content.current_projects.length > 3 && !all_projects && (
            <p>
              <UniversalLink
                href="#"
                onClick={() => {
                  set_all_projects((prev_all_projects) => true);
                }}
              >
                <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
                <FormattedMessage
                  id="more_projects"
                  defaultMessage="More projects"
                />
              </UniversalLink>
            </p>
          )}
        </Container>
      )}

      {content.publications_total ? (
        <Container className={'person-listing publications'}>
          <h2>
            <FormattedMessage
              id="recent_publications_at_the_ipn"
              defaultMessage="Recent publications at the IPN"
            />
          </h2>
          <RenderBlocks
            {...props}
            content={{
              blocks: {
                'person-list-publications': {
                  '@type': 'listing',
                  querystring: {
                    limit: '3',
                    query: [
                      {
                        i: 'portal_type',
                        o: 'plone.app.querystring.operation.selection.any',
                        v: ['publication'],
                      },
                      {
                        i: 'contributors_by_uuid',
                        o: 'plone.app.querystring.operation.selection.is',
                        v: content.uuid,
                      },
                    ],
                    sort_on: 'sortable_publication_date',
                    sort_order: 'ascending',
                  },
                  variation: 'publications',
                  linkTitle: (
                    <FormattedMessage
                      id="more_publications"
                      defaultMessage="More Publications"
                    />
                  ),
                  linkHref: [
                    {
                      '@id':
                        content.publications_total > 3
                          ? content.publication_search_uri +
                            '?query=[{"i"%3A"contributors_by_uuid"%2C"o"%3A"paqo.selection.any"%2C"v"%3A["' +
                            content.uuid +
                            '"]}]'
                          : '',
                    },
                  ],
                },
              },
              blocks_layout: { items: ['person-list-publications'] },
            }}
          />
        </Container>
      ) : (
        ''
      )}

      {content?.research_topics?.[0] && (
        <Container className={'person-listing research-topics'}>
          <h2>
            <FormattedMessage
              id="research_topics"
              defaultMessage="Research topics"
            />
          </h2>
          <ul>
            {content.research_topics.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </Container>
      )}

      {content?.position?.[0] && (
        <Container className={'person-table position'}>
          <h2>
            <FormattedMessage id="position" defaultMessage="Position" />
          </h2>
          <Table fixed>
            <Table.Body>
              {content.position.map((item) => (
                <Table.Row>
                  <Table.Cell>{item[0]}</Table.Cell>
                  <Table.Cell colSpan="3">{item[1]}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      )}

      {content?.profile?.[0] && (
        <Container className={'person-table profile'}>
          <h2>
            <FormattedMessage id="profile" defaultMessage="Profile" />
          </h2>
          <Table fixed>
            <Table.Body>
              {content.profile.map((item) => (
                <Table.Row>
                  <Table.Cell>{item[0]}</Table.Cell>
                  <Table.Cell colSpan="3">{item[1]}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      )}

      {content?.qualifications?.[0] && (
        <Container className={'person-table qualifications'}>
          <h2>
            <FormattedMessage
              id="qualifications"
              defaultMessage="Qualifications"
            />
          </h2>
          <Table fixed>
            <Table.Body>
              {content.qualifications.map((item) => (
                <Table.Row>
                  <Table.Cell>{item[0]}</Table.Cell>
                  <Table.Cell colSpan="3">{item[1]}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      )}
      <RenderBlocks
        {...props}
        content={{
          ...content,
          blocks_layout: {
            // ignore first block (title)
            items: props.content.blocks_layout.items?.slice(1),
          },
        }}
      />
    </Container>
  );
};
export default PersonView;
