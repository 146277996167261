import React from 'react';
import { Button } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

import xmarkSVG from 'volto-ipn/icons/xmark.svg';
import { Icon } from '@plone/volto/components';

const messages = defineMessages({
  currentFilters: {
    id: 'Current filters applied',
    defaultMessage: 'Current filters applied',
  },
  clearFilters: {
    id: 'Clear filters',
    defaultMessage: 'Clear filters',
  },
});

/**
 * A list of active filtered values and controls to clear those filters.
 *
 */
const FilterList = (props) => {
  const { data = {}, facets = {}, setFacets, isEditMode } = props;
  const definedFacets = data.facets || [];

  const totalFilters = definedFacets.filter(
    ({ field }) =>
      field &&
      Object.keys(facets).includes(field.value) &&
      !isEmpty(facets[field.value]),
  ).length;

  const intl = useIntl();

  return totalFilters ? (
    <Button
      className="clear-filter"
      onClick={(e) => {
        e.stopPropagation();
        !isEditMode && setFacets({});
      }}
    >
      <Icon
        name={xmarkSVG}
        color="#003F7D"
        className="dropdown-close-button"
        title={intl.formatMessage(messages.clearFilters)}
        size="22px"
      />

      {intl.formatMessage(messages.clearFilters)}
    </Button>
  ) : null;
};

export default FilterList;
