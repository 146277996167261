import React from 'react';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import chevronLeftSVG from 'volto-ipn/icons/chevron-left.svg';
import { Container, Segment } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  back: {
    id: 'back',
    defaultMessage: 'Back to overview',
  },
});

const NavHelper = () => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const show_navhelper =
    history.length > 2 && queryParams.has('show_navhelper');

  if (!show_navhelper) {
    return null;
  } else {
    return (
      <Segment className="navigationhelper" secondary vertical>
        <Container>
          <p>
            <a
              href={location}
              onClick={(event) => {
                event.preventDefault();
                history.go(-1);
              }}
            >
              <Icon
                size="16px"
                name={chevronLeftSVG}
                className={'linkprefix'}
              />
              {intl.formatMessage(messages.back)}
            </a>
          </p>
        </Container>
      </Segment>
    );
  }
};
export default NavHelper;
