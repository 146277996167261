/**
 * Add your helpers here.
 * @module helpers
 * @example
 * export { Api } from './Api/Api';
 */

export function scrollToTop(e, path = '/') {
  __CLIENT__ &&
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  // If no path, we just scroll up
  if (!path) {
    e.preventDefault();
    return false;
  }
}

// Decodes our base64 encoded emails only in the client
export function decodeEmail(email) {
  return __CLIENT__ && atob(email);
}
