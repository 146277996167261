import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';

const Template = (props) => {
  const { className, data } = props;
  const bgimage =
    data.image &&
    data.image[0] &&
    `${flattenToAppURL(data.image[0]['@id'])}/@@images/image`;

  return (
    <div className={cx('block largehero full', className)}>
      <div className="block-inner-wrapper full-width">
        <div className="hero-content">
          <div className="hero-body">
            <div className={`hero-text`}>
              {data.teaser && <h4>{data.teaser}</h4>}
              {data.title && <h1>{data.title}</h1>}
              {data.description && <h2>{data.description}</h2>}
            </div>
          </div>
          {data.copyright && (
            <div className="hero-copyright">
              Foto/Copyright: {data.copyright}
            </div>
          )}
          {data.image && data.image[0] && (
            <div className="hero-image">
              <img src={bgimage} alt={data.alt} loading="lazy" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Template.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Template;
