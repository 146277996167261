/**
 * ButtonMore component
 */

import React from 'react';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { Button } from 'semantic-ui-react';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import { Icon } from '@plone/volto/components';
import './styles.less';

/**
 * ButtonMore component.
 * @class ButtonMore
 * @extends Component
 */
const ButtonMore = ({ data, isEditMode }) => {
  let href = data.linkHref?.[0]?.['@id'] || '';
  let link = null;

  const wrapper = (children) => (
    <Button className={'ipn'}>
      {children}
      <Icon name={chevronSVG} size="19px" />
    </Button>
  );

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {wrapper(data.linkTitle || href)}
      </ConditionalLink>
    );
  } else if (href) {
    link = (
      <UniversalLink href={href}>
        {wrapper(data.linkTitle || href)}
      </UniversalLink>
    );
  }
  if (link) {
    return link;
  }
  return null;
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ButtonMore.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ButtonMore;
