import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { Table } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from '@plone/volto/components';

import openAccessSVG from 'volto-ipn/icons/open-access.svg';

import './Publication.less';

const PublicationView = (props) => {
  const { content } = props;
  const subheading_parts = [];
  if (content?.pub_type) {
    subheading_parts.push(content.pub_type);
  }
  if (content?.category) {
    subheading_parts.push(content.category);
  }
  if (content?.reviewed) {
    subheading_parts.push(
      <FormattedMessage id="peer_reviewed" defaultMessage="Peer reviewed" />,
    );
  }
  const subheading = subheading_parts.length > 0;

  return (
    <Container className="view-wrapper publication-view">
      <h1 className="documentFirstHeading">{content.title}</h1>
      {content?.translated_title && <h2>{content.translated_title}</h2>}
      {subheading && (
        <h2 className={'publication-heading-grey'}>
          {subheading_parts.map((item, i) => (
            <>
              {i > 0 && ' › '}
              {item}
            </>
          ))}
        </h2>
      )}
      <div className="block publication full">
        <div className="full-width">
          <Container className="publication-data full">
            <h2>
              <FormattedMessage
                id="publication_data"
                defaultMessage="Publication data"
              />
            </h2>
            <hr className={'blue'} />
            <Table fixed>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ width: '25%' }}>
                    <FormattedMessage id="by" defaultMessage="By" />
                  </Table.Cell>
                  <Table.Cell style={{ width: '75%' }}>
                    {content?.contributors?.map?.((cont, i) => {
                      const joiner = i ? ', ' : '';
                      var role = cont.role ? ' (' + cont.role + ')' : '';
                      return cont.href ? (
                        <>
                          {joiner}
                          <UniversalLink href={cont.href}>
                            {cont.title}
                          </UniversalLink>
                          {role}
                        </>
                      ) : (
                        joiner + cont.title + role
                      );
                    })}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage
                      id="original_language"
                      defaultMessage="Original language"
                    />
                  </Table.Cell>
                  <Table.Cell>{content?.original_language}</Table.Cell>
                </Table.Row>
                {content?.published_in && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage
                        id="published_in"
                        defaultMessage="Published in"
                      />
                    </Table.Cell>
                    <Table.Cell>{content.published_in}</Table.Cell>
                  </Table.Row>
                )}
                {(content?.pages || content?.num_pages) && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage id="pages" defaultMessage="Pages" />
                    </Table.Cell>
                    <Table.Cell>
                      {content?.pages || content?.num_pages}
                    </Table.Cell>
                  </Table.Row>
                )}
                {content?.edition && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage id="edition" defaultMessage="Edition" />
                    </Table.Cell>
                    <Table.Cell>{content?.edition}</Table.Cell>
                  </Table.Row>
                )}
                {content?.publisher && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage
                        id="editor_publisher"
                        defaultMessage="Editor (Publisher)"
                      />
                    </Table.Cell>
                    <Table.Cell>{content?.publisher}</Table.Cell>
                  </Table.Row>
                )}
                {content?.isbns?.[0] && (
                  <Table.Row>
                    <Table.Cell>ISBN</Table.Cell>
                    <Table.Cell>{content?.isbns.join(', ')}</Table.Cell>
                  </Table.Row>
                )}
                {content?.issns?.[0] && (
                  <Table.Row>
                    <Table.Cell>ISSN</Table.Cell>
                    <Table.Cell>{content?.issns.join(', ')}</Table.Cell>
                  </Table.Row>
                )}
                {(content?.output_media || content?.type_description) && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage
                        id="additional_information"
                        defaultMessage="Additional information"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {content?.output_media}
                      {content?.output_media &&
                        content?.type_description &&
                        ', '}
                      {content?.type_description}
                    </Table.Cell>
                  </Table.Row>
                )}
                {content.electronic_versions?.[0] && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage
                        id="doi_link"
                        defaultMessage="DOI/Link"
                      />
                    </Table.Cell>
                    <Table.Cell>
                      {content?.electronic_versions?.map?.((ev, i) => {
                        const joiner = i ? ', ' : '';
                        return (
                          <>
                            {joiner}
                            <UniversalLink href={ev.href}>
                              {ev.text}
                            </UniversalLink>
                            {ev.open_access && (
                              <>
                                {' '}
                                <Icon
                                  name={openAccessSVG}
                                  title="(Open Access)"
                                  size="22px"
                                />
                              </>
                            )}
                          </>
                        );
                      })}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage
                      id="publication_status"
                      defaultMessage="Publication status"
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {content?.status &&
                      [content.status, content.status_since].join(' – ')}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Container>
        </div>
      </div>
      <Container>
        {content?.description?.split?.('\n\n').map?.((par) => (
          <p>{par}</p>
        ))}
      </Container>
      {content?.doi && (
        <div className="block publication full">
          <div className="full-width doi-footer-wrapper">
            <Container className="doi-footer">
              <h4>DOI</h4>
              <p>
                <UniversalLink href={content.doi.href}>
                  {content.doi.href}
                </UniversalLink>
                {content.doi.open_access && (
                  <>
                    {' '}
                    <Icon name={openAccessSVG} size="22px" />
                  </>
                )}
              </p>
              {content.doi?.version && (
                <p class="doi-version-type">{content.doi.version}</p>
              )}
            </Container>
          </div>
        </div>
      )}
    </Container>
  );
};
export default PublicationView;
