import React from 'react';
import { researchlines_mapping, mappingdata } from './config';
import { shallowEqual, useSelector } from 'react-redux';
import cx from 'classnames';
import { ConditionalLink } from '@plone/volto/components';
import { Grid } from 'semantic-ui-react';

const Researchlines = (props) => {
  const { researchlines, showname } = props;
  const lang = useSelector((state) => state.intl.locale);
  // The id's (number) of all passed researchlines.
  const numbers = researchlines
    ? researchlines.map((item) => researchlines_mapping[item]?.id)
    : [];

  const { content } = useSelector(
    (state) => ({
      content: state.content?.data,
    }),
    shallowEqual,
  );

  const displayedlines = (researchlines) => {
    if (content?.['@type'] === 'researchline') {
      const all_lines = Object.keys(researchlines_mapping);
      return [all_lines[0], all_lines[2], all_lines[4], all_lines[6]];
    }
    return researchlines;
  };

  return (
    <div className="researchlinesWrapper">
      <div className="block researchlines full">
        <div className="full-width">
          {researchlines &&
            displayedlines(researchlines).map((item) => (
              <div key={item} className="researchlinesLine">
                <img
                  src={researchlines_mapping[item]?.url || ''}
                  alt=""
                  loading="lazy"
                />
              </div>
            ))}
        </div>
      </div>
      <Grid doubling className="researchlinesIcons" columns={4}>
        {mappingdata.map((item) => {
          // only display data if this item is passed
          if (numbers.includes(item.id)) {
            return (
              <Grid.Column textAlign="center" key={item.id}>
                <ConditionalLink to={item.target?.[lang]} condition={showname}>
                  <img
                    src={item.icon || ''}
                    alt=""
                    height="100px"
                    width="100px"
                    loading="lazy"
                  />
                  {showname && (
                    <div key={item.id} className={cx('researchlinesName')}>
                      {item.title?.[lang]}
                    </div>
                  )}
                </ConditionalLink>
              </Grid.Column>
            );
          } else {
            return <Grid.Column className="emptyColumn" key={item.id} />;
          }
        })}
      </Grid>
    </div>
  );
};

export default Researchlines;
