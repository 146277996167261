export const Schema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'description', 'researchlines', 'showname'],
      },
    ],
    properties: {
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'text',
      },
      researchlines: {
        title: 'Researchlines',
        choices: [
          [
            'forschungslinie-fachliches-lernen-im-vorschulischen-und-schulischen-bereich',
            'Forschungslinie fachliches Lernen im vorschulischen und schulischen Bereich',
          ],
          [
            'forschungslinie-professionelle-kompetenz-von-lehrkraften-und-padagogischem-personal',
            'Forschungslinie professionelle Kompetenz von Lehrkraften und pädagogischem Personal',
          ],
          [
            'forschungslinie-methodenforschung-und-maschinelles-lernen',
            'Methodenforschung und Maschinelles Lernen',
          ],
          [
            'forschungslinie-wissenschaftskommunikation-und-extracurriculares-lernen',
            'Wissenschaftskommunikation und extracurriculares Lernen',
          ],
        ],
        widget: 'array',
        default: [],
        noValueOption: false,
      },
      showname: {
        title: 'Show name and link to researchline',
        type: 'boolean',
      },
    },
  };
};

export default Schema;
