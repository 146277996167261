/**
 * Image/video caption component.
 * @module components/Image/Caption
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import('./Caption.less');
/**
 * Image/video caption component class.
 * @function Caption
 * @params {string} as HTML tag used to wrap the caption.
 * @params {string} title Image title.
 * @params {string} description Image description.
 * @params {object} credit Credit text.
 * @returns {string} Markup of the component.
 */
const Caption = ({
  as = 'figcaption',
  title,
  description,
  credit,
  zoomed,
  showPrefix = true,
}) => {
  const As = as;

  return (
    <As
      className={cx({
        zoomed: zoomed,
      })}
    >
      {credit && (
        <div
          className={cx('credit', {
            'with-description': description,
          })}
        >
          {showPrefix && (
            <>
              <FormattedMessage
                id="copyright"
                defaultMessage="Photo/Copyright"
              />
              :{' '}
            </>
          )}
          {credit}
        </div>
      )}
      {title && <div className="title">{title}</div>}
      {description && (
        <div
          className={cx('description', {
            'with-credit': credit,
          })}
        >
          {description.split('\n').map((line, index) => (
            <div key={index}>{line || '\u00A0'}</div>
          ))}
        </div>
      )}
    </As>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Caption.propTypes = {
  credit: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Caption;
