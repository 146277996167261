import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getNavroot } from '@plone/volto/actions';
import { hasApiExpander, getBaseUrl } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import searchSVG from 'volto-ipn/icons/search.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import { flattenToAppURL } from '@plone/volto/helpers';

import './SearchWidget.less';

const messages = defineMessages({
  search: {
    id: 'Search',
    defaultMessage: 'Search',
  },
  searchSite: {
    id: 'Search Site',
    defaultMessage: 'Search Site',
  },
});

const SearchWidget = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [text, setText] = useState('');
  const [open_search, setOpenSearch] = useState('');
  const history = useHistory();
  const onChangeText = (event, { value }) => {
    setText(value);
  };
  const navroot = useSelector((state) => state.navroot?.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/de';
  const target = `${navRootPath}/advanced-search`;
  const pathname = props.pathname;
  const onSubmit = (event) => {
    const path =
      pathname?.length > 0 ? `&path=${encodeURIComponent(pathname)}` : '';

    let query = `query=[{"i":"SearchableText","o":"paqo.string.contains","v":"${encodeURIComponent(
      text,
    )}"}]`;
    history.push(`${flattenToAppURL(target)}?${query}${path}`);
    // reset input value
    setText('');
    setOpenSearch(false);
    event.preventDefault();
  };

  const onShowSearch = (show) => {
    setOpenSearch(show);
    let input = document.querySelector(
      '.searchbox input[name="SearchableText"]',
    );
    if (show && input) {
      // What? https://stackoverflow.com/a/1096938
      // Does not work without timeout. Could be the slideIn
      window.setTimeout(() => input.focus(), 0);
    }
    // reset input after we close
    setText('');
  };

  useEffect(() => {
    if (!hasApiExpander('navroot', getBaseUrl(pathname))) {
      dispatch(getNavroot(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  return (
    <span className="searchbox-wrapper">
      <OutsideClickHandler
        onOutsideClick={() => {
          onShowSearch(false);
        }}
      >
        <Form action={target} onSubmit={onSubmit}>
          <Form.Field className="searchbox">
            <Input
              aria-label={intl.formatMessage(messages.search)}
              onChange={onChangeText}
              name="SearchableText"
              value={text}
              transparent
              className={open_search ? 'expanded' : ''}
              autoComplete="off"
              placeholder={intl.formatMessage(messages.searchSite)}
              title={intl.formatMessage(messages.search)}
            />
          </Form.Field>
        </Form>
        <button
          aria-label={intl.formatMessage(messages.search)}
          onClick={() => {
            onShowSearch(true);
          }}
        >
          <Icon
            name={searchSVG}
            color="#003F7D"
            className="search-svg"
            size="22px"
          />
        </button>
      </OutsideClickHandler>
    </span>
  );
};

export default SearchWidget;
