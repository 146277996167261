import React from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { Grid, Container, Table } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import ResearchLines from 'volto-ipn/components/Blocks/researchlines/researchlines';
import { UniversalLink } from '@plone/volto/components';
import { researchline_style } from 'volto-ipn/components/Blocks/researchlines/utils';

import './Project.less';

const ProjectView = (props) => {
  const { content } = props;
  const researchlines = content?.researchlines?.map((item) => item.token);
  const has_project_color = researchlines.length === 1;
  let bg_color = null;
  if (has_project_color) {
    bg_color = researchline_style([researchlines[0]]);
    const lighter = bg_color.backgroundColor.slice(0, -1) + ', 0.13)';
    bg_color = { backgroundColor: lighter };
  } else {
    bg_color = { backgroundColor: '#E6ECF2' };
  }

  const period_start = <FormattedDate value={content.period_start} />;
  const period_end = <FormattedDate value={content.period_end} />;
  const has_logo = content?.image?.download;

  const truncate = (str, maxlength) => {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  };
  const description =
    content?.description && truncate(content.description, 300);

  // The slice removes the title block that should always be there and the
  // description block following it, which may have been deleted, though.
  const descr_cand = props.content.blocks_layout.items?.[1];
  const blocks_layout_items = props.content.blocks_layout.items.slice(
    props.content.blocks?.[descr_cand]?.['@type'] === 'description' ? 2 : 1,
  );

  return (
    <Container className="view-wrapper project-view">
      <h1 className="documentFirstHeading">
        {content.acronym}
        <br />
        {content.project_title}
      </h1>
      {researchlines.length > 0 && (
        <ResearchLines researchlines={researchlines} />
      )}
      <Grid stackable>
        <Grid.Row>
          {has_logo && (
            <Grid.Column width="4">
              <p className="block image align full">
                <img
                  alt="Project Logo"
                  src={flattenToAppURL(content.image.scales.mini.download)}
                  loading="lazy"
                />
              </p>
            </Grid.Column>
          )}
          <Grid.Column width={has_logo ? '8' : '12'}>
            {description &&
              description
                .split?.('\n\n')
                ?.map?.((par) => <p className="documentDescription">{par}</p>)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="block project full">
        <div className="full-width" style={bg_color}>
          <Container className="project-data full">
            <h2>
              <FormattedMessage
                id="project_data"
                defaultMessage="Project data"
              />
            </h2>
            <hr className={'blue'} />
            <Table fixed>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage
                      id="researchlines"
                      defaultMessage="Research lines"
                    />
                  </Table.Cell>
                  <Table.Cell colSpan={3}>
                    {content?.researchlines?.map?.((rl, i) => (
                      <>
                        {i > 0 && ', '}
                        <UniversalLink href={rl.href}>{rl.title}</UniversalLink>
                      </>
                    ))}
                  </Table.Cell>
                </Table.Row>
                {content?.org_units?.[0] && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage
                        id="org_units"
                        defaultMessage="Departments"
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={3}>
                      {content?.org_units?.map?.((ou, i) => (
                        <>
                          {i > 0 && ', '}
                          {ou.href ? (
                            <UniversalLink href={ou.href}>
                              {ou.title}
                            </UniversalLink>
                          ) : (
                            ou.title
                          )}
                        </>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
                {content?.funding?.[0] && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage id="funding" defaultMessage="Funding" />
                    </Table.Cell>
                    <Table.Cell colSpan={3}>
                      {content?.funding?.map?.((funding, i) => (
                        <>
                          {i > 0 && ', '}
                          {funding.name}
                          {funding.periods?.[0] && (
                            <>
                              {' ('}
                              {funding.periods.map((period, j) => (
                                <>
                                  {j > 0 && ', '}
                                  <FormattedMessage
                                    id="from-start-to-end"
                                    defaultMessage="{start}–{end}"
                                    values={{
                                      start: (
                                        <FormattedDate value={period.start} />
                                      ),
                                      end: <FormattedDate value={period.end} />,
                                    }}
                                  />
                                </>
                              ))}
                              {')'}
                            </>
                          )}
                        </>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
                {(content?.period_start || content?.period_end) && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage id="period" defaultMessage="Period" />
                    </Table.Cell>
                    <Table.Cell colSpan={3}>
                      {content.period_start ? (
                        content.period_end ? (
                          <FormattedMessage
                            id="from-start-to-end"
                            defaultMessage="{start}–{end}"
                            values={{ start: period_start, end: period_end }}
                          />
                        ) : (
                          <FormattedMessage
                            id="since-start"
                            defaultMessage="since {start}"
                            values={{ start: period_start }}
                          />
                        )
                      ) : (
                        <FormattedMessage
                          id="until-end"
                          defaultMessage="until {end}"
                          values={{ end: period_end }}
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>
                    <FormattedMessage id="status" defaultMessage="Status" />
                  </Table.Cell>
                  <Table.Cell colSpan={3}>
                    {(content?.status && (
                      <FormattedMessage id="current" defaultMessage="current" />
                    )) || (
                      <FormattedMessage
                        id="completed"
                        defaultMessage="completed"
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
                {content?.participants?.[0] && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage
                        id="participants_at_ipn"
                        defaultMessage="IPN researchers"
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={3}>
                      {content?.participants?.map?.((part, i) => (
                        <>
                          {i > 0 && ', '}
                          <UniversalLink href={part.href}>
                            {part.title}
                          </UniversalLink>
                          {part.role && <> ({part.role})</>}
                        </>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                )}
                {content?.collaborative_partners && (
                  <Table.Row>
                    <Table.Cell>
                      <FormattedMessage
                        id="collaborative_partners"
                        defaultMessage="Members of the research alliance"
                      />
                    </Table.Cell>
                    <Table.Cell colSpan={3}>
                      <p className="collaborative_partners">
                        {content?.collaborative_partners}
                      </p>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Container>
        </div>
      </div>
      <RenderBlocks
        {...props}
        content={{
          ...content,
          blocks_layout: {
            // ignore first block (title)
            items: blocks_layout_items,
          },
        }}
      />

      {
        // TODO: refactor the following block as it has been copied straight
        // from Person.jsx, along with the styling.
      }
      {content.publications_total ? (
        <Container className={'project-listing publications'}>
          <h2>
            <FormattedMessage
              id="recent_publications"
              defaultMessage="Recent publications"
            />
          </h2>
          <RenderBlocks
            {...props}
            content={{
              blocks: {
                'project-list-publications': {
                  '@type': 'listing',
                  querystring: {
                    limit: '3',
                    query: [
                      {
                        i: 'portal_type',
                        o: 'plone.app.querystring.operation.selection.any',
                        v: ['publication'],
                      },
                      {
                        i: 'projects',
                        o: 'plone.app.querystring.operation.selection.is',
                        v: content.uuid,
                      },
                    ],
                    sort_on: 'sortable_publication_date',
                    sort_order: 'ascending',
                  },
                  variation: 'publications',
                  linkTitle: (
                    <FormattedMessage
                      id="more_publications"
                      defaultMessage="More Publications"
                    />
                  ),
                  linkHref: [
                    {
                      '@id':
                        content.publications_total > 3
                          ? content.publication_search_uri +
                            '?query=[{"i"%3A"projects"%2C"o"%3A"paqo.selection.any"%2C"v"%3A["' +
                            content.uuid +
                            '"]}]'
                          : '',
                    },
                  ],
                },
              },
              blocks_layout: { items: ['project-list-publications'] },
            }}
          />
        </Container>
      ) : (
        ''
      )}
    </Container>
  );
};
export default ProjectView;
