import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { Table } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';

const PersonListingBlockTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <UniversalLink href={flattenToAppURL(href)}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
        {linkTitle || href}
      </UniversalLink>
    );
  } else if (href) {
    link = (
      <UniversalLink href={href}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />{' '}
        {linkTitle || href}
      </UniversalLink>
    );
  }

  return (
    <>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="name" defaultMessage="Name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="org_unit" defaultMessage="Department" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage
                id="phone_number"
                defaultMessage="Phone Number"
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {items.map(
            (item) =>
              item['@type'] === 'person' && (
                <Table.Row verticalAlign="top" key={item['@id']}>
                  <Table.Cell singleLine>
                    <ConditionalLink
                      to={item?.['@id'] + '?show_navhelper=1'}
                      condition={!isEditMode}
                    >
                      {item.listing_name}
                    </ConditionalLink>
                  </Table.Cell>
                  <Table.Cell>{item.org_units?.join?.(', ')}</Table.Cell>
                  <Table.Cell>{item.phone_numbers?.join(', ')}</Table.Cell>
                </Table.Row>
              ),
          )}
        </Table.Body>
      </Table>
      {link && <div className="linkMore">{link}</div>}
    </>
  );
};
PersonListingBlockTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default PersonListingBlockTemplate;
