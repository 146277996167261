import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { isInternalURL } from '@plone/volto/helpers';
import { MaybeWrap } from '@plone/volto/components';
import cx from 'classnames';
import config from '@plone/volto/registry';
import { UniversalLink } from '@plone/volto/components';
import { Icon } from '@plone/volto/components';
import pdfSVG from 'volto-ipn/icons/file-pdf-regular.svg';
import pptSVG from 'volto-ipn/icons/file-powerpoint-regular.svg';
import fileSVG from 'volto-ipn/icons/file-regular.svg';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserFileTemplate = (props) => {
  const { className, data, isEditMode } = props;
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  const HeadlineTag = data?.headlineTag || 'h2';

  const Image = config.getComponent('Image').component;
  const { openExternalLinkInNewTab } = config.settings;
  const target_url =
    href && (data?.externalURL ? data?.externalURL : href && href?.['@id']);
  const download_url = !isInternalURL(target_url)
    ? target_url
    : target_url.includes('@@download/file')
    ? target_url
    : `${target_url}/@@download/file`;

  const fileIcon = (mime_type) => {
    if (mime_type === 'application/pdf') {
      return pdfSVG;
    } else if (mime_type === 'application/vnd.ms-powerpoint') {
      return pptSVG;
    } else {
      return fileSVG;
    }
  };

  return (
    <div className={cx('block teaser', className)}>
      <>
        {!href && isEditMode && (
          <Message>
            <div className="teaser-item placeholder">
              <img src={imageBlockSVG} alt="" />
              <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
            </div>
          </Message>
        )}
        {target_url && (
          <div className="teaser-item default file">
            {!data.hideImage &&
              (href.hasPreviewImage || href.image_field || image) && (
                <div className="image-wrapper">
                  <Image
                    item={image || href}
                    imageField={image ? image.image_field : href.image_field}
                    alt=""
                    loading="lazy"
                    responsive={true}
                  />
                </div>
              )}

            <div className="content">
              <MaybeWrap
                condition={!isEditMode}
                as={UniversalLink}
                href={target_url}
                target={
                  data.openLinkInNewTab ||
                  (openExternalLinkInNewTab && !isInternalURL(target_url))
                    ? '_blank'
                    : null
                }
              >
                {data?.head_title && (
                  <div className="headline">{data.head_title}</div>
                )}
                <HeadlineTag>{data?.title}</HeadlineTag>
              </MaybeWrap>
              {!data.hide_description && <p>{data?.description}</p>}

              <UniversalLink
                title={data?.title}
                href={download_url}
                data-size-fmt={href.getObjSize}
              >
                <Icon
                  name={fileIcon(href.mime_type)}
                  className=""
                  size="19px"
                />{' '}
                Download
                {href.getObjSize && ` (${href.getObjSize})`}
              </UniversalLink>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

TeaserFileTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserFileTemplate;
