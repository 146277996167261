import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { Icon } from '@plone/volto/components';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';

import './Publications.less';

const PublicationListingBlockTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
  querystring,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <UniversalLink href={flattenToAppURL(href)}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
        {linkTitle || href}
      </UniversalLink>
    );
  } else if (href) {
    link = (
      <UniversalLink href={href}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />{' '}
        {linkTitle || href}
      </UniversalLink>
    );
  }

  if (!querystring?.limit || querystring.limit > 5) {
    let prev_year = 0;
    items.forEach((item) => {
      if (item.status_year && item.status_year !== prev_year) {
        prev_year = item.status_year;
        item.first_of_year = true;
      }
    });
  }

  const format_contributor = (cont) =>
    cont.first_name
      ? `${cont.first_name.slice(0, 1)}. ${cont.last_name}`
      : cont.last_name;

  return (
    <>
      <ul>
        {items.map((item) => (
          <li data-first-of-year={item.first_of_year ? item.status_year : null}>
            <div className={'contributors-line'}>
              {item.authors &&
                item.authors
                  .map((cont) => <>{format_contributor(cont)}</>)
                  .concat(
                    item.editors.map((cont) => (
                      <>
                        {format_contributor(cont)}
                        {' ('}
                        <FormattedMessage
                          id="editor_abbreviated"
                          defaultMessage="Ed."
                        />
                        {')'}
                      </>
                    )),
                  )
                  .map((cont, i, arr) => (
                    <>
                      {i ? (i < arr.length - 1 ? ', ' : ' & ') : ''}
                      {cont}
                    </>
                  ))}
              {item.status_year &&
                ' (' +
                  (item.status_month
                    ? item.status_month.toString().padStart(2, '0') + '.'
                    : '') +
                  `${item.status_year})`}
            </div>
            <div className={'title-line'}>
              <ConditionalLink
                to={item?.['@id'] + '?show_navhelper=1'}
                condition={!isEditMode}
              >
                {item.title}
              </ConditionalLink>
            </div>
            <div className={'published-line'}>
              {item.pub_type}
              {item.host_data?.title && `: ${item.host_data.title}`}
            </div>
          </li>
        ))}
      </ul>
      {link && <div className="linkMore">{link}</div>}
    </>
  );
};
PublicationListingBlockTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default PublicationListingBlockTemplate;
