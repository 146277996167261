/**
 * Search component.
 * @module components/theme/Search/Search
 */

import qs from 'query-string';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';

const Search = (props) => {
  const searchableText = qs.parse(props.history.location.search).SearchableText;

  const navroot = useSelector((state) => state.navroot.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/de';
  // const navRootType = navroot?.navroot?.['@type'];
  // const isSubsite = !["Plone Site", "LRF"].includes(navRootType);
  const target = `${navRootPath}/advanced-search`;

  if (searchableText) {
    let query = `query=[{"i":"SearchableText","o":"paqo.string.contains","v":"${encodeURIComponent(
      searchableText,
    )}"}]`;
    props.history.push(`${target}?${query}`);
  } else {
    props.history.push(target);
  }

  return `Redirect to custom search at ${target}`;
};

export default Search;
