/**
 * Querystring actions.
 * @module actions/querystring/querystring
 */

import { GET_QUERYSTRING } from '@plone/volto/constants/ActionTypes';
import { getBaseUrl } from '@plone/volto/helpers';

/**
 * Get querystring.
 * @function getQuerystring
 * @returns {Object} Get querystring action.
 */

/**
 * Override to pass the current context's path to the endpoint
 * To be able to filter the vocabulary values in a KeywordsVocabulary
 */

export function getQuerystring() {
  const path = typeof window !== 'undefined' ? window.location.pathname : '';
  return {
    type: GET_QUERYSTRING,
    request: {
      op: 'get',
      path: path ? '/@querystring?path=' + getBaseUrl(path) : '/@querystring',
    },
  };
}
