import React from 'react';
import PropTypes from 'prop-types';
import {
  Component,
  ConditionalLink,
  UniversalLink,
} from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import ConditionalDate from 'volto-ipn/components/ConditionalDate/ConditionalDate';
import { Icon } from '@plone/volto/components';
import './NewsTemplate.less';

const IPNNewsListingBlockTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
  showDate,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';
  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  const truncate = (str, maxlength) => {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  };

  return (
    <>
      <div className="items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            {showDate && (
              <p className="newslisting-date">
                <ConditionalDate item={item} />
              </p>
            )}
            <div className="listing-body">
              {item?.image_field && (
                <ConditionalLink item={item} condition={!isEditMode}>
                  <Component
                    componentName="PreviewImage"
                    item={item}
                    className="preview-image"
                    alt={item.alttext}
                  />
                </ConditionalLink>
              )}
              <div>
                <ConditionalLink
                  item={item}
                  condition={!isEditMode}
                  className="item-heading"
                >
                  <h4>{item.title || item.id}</h4>
                </ConditionalLink>
                <p>{truncate(item.description, 300)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      {link && (
        <div className="footer">
          <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
          {link}
        </div>
      )}
    </>
  );
};
IPNNewsListingBlockTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default IPNNewsListingBlockTemplate;
