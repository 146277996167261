import PropTypes from 'prop-types';
import { LanguageSelector } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import { useSelector } from 'react-redux';

import './HeaderTop.less';

function HeaderTop(props) {
  const lang = useSelector((state) => state.intl.locale);

  return (
    <div className="header-top mobile hidden tablet hidden">
      {lang === 'de' ? (
        <ul>
          <li>
            <UniversalLink
              href="/de/forschen/publikationen"
              title="Publikationen"
              onClick={props.toggleMobileMenu}
            >
              Publikationen
            </UniversalLink>
          </li>
          <li>
            <UniversalLink
              href="/de/das-ipn/abteilungen/zentrale-dienste/presse-oeffentlichkeitsarbeit"
              title="Presse"
              onClick={props.toggleMobileMenu}
            >
              Presse
            </UniversalLink>
          </li>
          <li>
            <UniversalLink
              href="/de/das-ipn/ueber-uns/karriere"
              title="Karriere"
              onClick={props.toggleMobileMenu}
            >
              Karriere
            </UniversalLink>
          </li>
          <li>
            <UniversalLink
              href="/de/das-ipn/leichte-sprache"
              title="Leichte Sprache"
              onClick={props.toggleMobileMenu}
            >
              Leichte Sprache
            </UniversalLink>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <UniversalLink
              href="/en/research/publications"
              title="Publications"
              onClick={props.toggleMobileMenu}
            >
              Publications
            </UniversalLink>
          </li>
          <li>
            <UniversalLink
              href="/en/the-ipn/departments/zentrale-dienste/press-public-relations"
              title="Press"
              onClick={props.toggleMobileMenu}
            >
              Press
            </UniversalLink>
          </li>
          <li>
            <UniversalLink
              href="/en/the-ipn/about-us/career"
              title="Career"
              onClick={props.toggleMobileMenu}
            >
              Career
            </UniversalLink>
          </li>
          <li>
            <UniversalLink
              href="/en/the-ipn/easy-language"
              title="Easy Language"
              onClick={props.toggleMobileMenu}
            >
              Easy Language
            </UniversalLink>
          </li>
        </ul>
      )}
      <LanguageSelector />
    </div>
  );
}

HeaderTop.propTypes = {
  toggleMobileMenu: PropTypes.func,
};

export default HeaderTop;
