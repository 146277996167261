import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import penSVG from '@plone/volto/icons/pen.svg';
import { Segment, Grid, Container } from 'semantic-ui-react';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import React from 'react';
import LinesFooter from './lines_footer.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  hasBlocksData,
  flattenToAppURL,
  BodyClass,
} from '@plone/volto/helpers';
import { searchContent } from '@plone/volto/actions';
import { isEmpty, find } from 'lodash';

import './FooterMiddle.less';

const FooterBlock = ({ footer_document, slice_start = 1 }) => (
  <RenderBlocks
    as="div"
    content={{
      ...footer_document,
      blocks_layout: {
        items: footer_document.blocks_layout.items.slice(
          slice_start,
          slice_start + 2,
        ),
      },
    }}
  />
);

const FooterMiddle = ({ props }) => {
  const { lang, footer_html, actions } = useSelector((state) => ({
    lang: state.intl.locale,
    footer_html: state.search.subrequests.footer_html?.items,
    actions: state.actions?.actions ?? {},
  }));

  const dispatch = useDispatch();
  const navroot = useSelector((state) => state.navroot.data);
  const navRootPath = flattenToAppURL(navroot?.navroot?.['@id']) || '/';
  const in_subsite = navroot?.navroot?.['@type'] === 'subsite';
  const col_width = in_subsite ? 4 : 3;

  const footer_path = navRootPath + '/footer';
  // Checks for edit action on current context. Not Exactly footer document
  const editAction = find(actions.object, { id: 'edit' });

  React.useEffect(() => {
    dispatch(
      searchContent(
        footer_path,
        {
          review_state: 'published',
          fullobjects: true,
        },
        'footer_html',
      ),
    );
  }, [dispatch, lang, footer_path]);

  let footer_document;
  if (!isEmpty(footer_html)) {
    footer_document = footer_html[0];
  }

  return (
    <>
      <img className="lines-footer" src={LinesFooter} alt="" />
      <Segment vertical className="curved-footer">
        <div>
          <div className="blueLight-bg" />
        </div>
      </Segment>
      <Segment vertical padded className="footer-middle blueLight-bg">
        <Container>
          <Grid>
            {!in_subsite && (
              <Grid.Column mobile={12} computer={3}>
                <div>
                  <address>
                    <h2>
                      <FormattedMessage
                        id="IPN full name"
                        defaultMessage="IPN - Leibniz Institute for Science and Mathematics Education"
                      />
                    </h2>
                    <p>
                      Olshausenstraße 62
                      <br />
                      24118 Kiel
                      <br />
                      Telefon 0431 - 880 5084
                      <br />
                      E-Mail:
                      <a href="mailto:info@leibniz-ipn.de">
                        {' '}
                        info@leibniz-ipn.de
                      </a>
                    </p>
                  </address>
                </div>
              </Grid.Column>
            )}
            {hasBlocksData(footer_document) && (
              <>
                <Grid.Column width={col_width} className="mobile hidden">
                  <FooterBlock footer_document={footer_document} />
                </Grid.Column>
                <Grid.Column width={col_width} className="mobile hidden">
                  <FooterBlock
                    footer_document={footer_document}
                    slice_start={3}
                  />
                </Grid.Column>
                <Grid.Column width={col_width} className="mobile hidden">
                  <FooterBlock
                    footer_document={footer_document}
                    slice_start={5}
                  />
                  <FooterBlock
                    footer_document={footer_document}
                    slice_start={7}
                  />
                  {editAction && (
                    <Link
                      aria-label="Edit"
                      className="edit"
                      to={`${footer_path}/edit`}
                    >
                      <Icon
                        name={penSVG}
                        size="30px"
                        className="circled"
                        title="Edit"
                      />
                    </Link>
                  )}
                </Grid.Column>
                <Grid.Column
                  width={12}
                  only="mobile"
                  className="footer-mobile-toggle"
                >
                  {[1, 3, 5, 7].map((index) => {
                    return (
                      <FooterBlock
                        key={index}
                        footer_document={footer_document}
                        slice_start={index}
                      />
                    );
                  })}
                </Grid.Column>
              </>
            )}
          </Grid>
        </Container>
        <div />
      </Segment>
      {in_subsite && (
        <>
          <BodyClass
            className={`within-subsite subsite-${navroot?.navroot?.['id']}`}
          />
          <style>{navroot?.navroot?.css}</style>
        </>
      )}
    </>
  );
};

export default FooterMiddle;
