/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Grid, Container, List, Segment } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';
import AuditLogo from './audit_logo.svg';
import { Image } from 'semantic-ui-react';
import { Anontools } from '@plone/volto/components';
import './FooterBottom.less';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const FooterBottom = ({ intl }) => {
  const { settings } = config;
  const { lang, siteActions = [] } = useSelector(
    (state) => ({
      lang: state.intl.locale,
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );
  const navroot = useSelector((state) => state.navroot.data);
  const in_subsite = navroot?.navroot?.['@type'] === 'subsite';

  return (
    <>
      <Segment vertical className="footer-bottom curved-footer">
        <div>
          <div className="blueDark-bg" />
        </div>
      </Segment>
      <Segment
        vertical
        padded
        role="list"
        textAlign="center"
        className="footer-bottom blueDark-bg"
      >
        <Container>
          <Grid stackable columns={5}>
            {siteActions?.length
              ? map(siteActions, (item) => (
                  <Grid.Column
                    textAlign="right"
                    role="listitem"
                    className="item"
                    key={item.id}
                  >
                    <UniversalLink
                      className="item"
                      href={
                        settings.isMultilingual
                          ? `/${lang}/${
                              item.url
                                ? flattenToAppURL(item.url)
                                : addAppURL(item.id)
                            }`
                          : item.url
                          ? flattenToAppURL(item.url)
                          : addAppURL(item.id)
                      }
                    >
                      {item?.title}
                    </UniversalLink>
                  </Grid.Column>
                ))
              : null}
            <Grid.Column className="logo-login item">
              <Image
                src={AuditLogo}
                alt="Audit Logo Beruf und Familie"
                verticalAlign="top"
                className="audit-logo"
                floated="right"
              />
            </Grid.Column>
          </Grid>
          <hr className={'grey'} />
          <List horizontal className="last-line">
            <List.Item>
              <Anontools />
            </List.Item>
            <List.Item>
              <p className="footer-end">
                © {new Date().getFullYear()}{' '}
                {in_subsite ? (
                  navroot?.navroot?.copyright_footer
                ) : (
                  <FormattedMessage
                    id="IPN full name"
                    defaultMessage="IPN - Leibniz Institute for Science and Mathematics Education"
                  />
                )}
              </p>
            </List.Item>
          </List>
        </Container>
      </Segment>
    </>
  );
};

/**
 * Property types.
 * @property {
  Object;
} propTypes Property types.
 * @static
 */
FooterBottom.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(FooterBottom);
