import PropTypes from 'prop-types';
import React from 'react';
import { List, Image } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';
import cx from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router';

import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import { flattenToAppURL, getParentUrl } from '@plone/volto/helpers';
import { Icon, UniversalLink } from '@plone/volto/components';
import { withContentNavigation } from './withContentNavigation';
import HeaderTop from 'volto-ipn/components/Theme/Header/HeaderTop';

import leftIcon from '@plone/volto/icons/left-key.svg';

const messages = defineMessages({
  goBack: {
    id: 'Go back',
    defaultMessage: 'Go back',
  },
});

function renderNode(node, parentLevel, setShowRootNav, toggleMobileMenu) {
  const level = parentLevel + 1;
  return (
    <List.Item
      key={node['@id']}
      active={node.is_current}
      className={`level-${level}`}
    >
      <List.Content>
        {node.type !== 'link' ? (
          <>
            <RouterLink
              to={flattenToAppURL(node.href)}
              title={node.description}
              onClick={() => {
                toggleMobileMenu();
                setShowRootNav(false);
              }}
              className={cx(`contenttype-${node.type}`, {
                in_path: node.is_in_path,
              })}
            >
              {node.thumb ? <Image src={flattenToAppURL(node.thumb)} /> : ''}
              {node.title}
              {node.is_current ? (
                <List.Content className="active-indicator">
                  <Icon name={leftIcon} size="30px" />
                </List.Content>
              ) : (
                ''
              )}
            </RouterLink>
            {node.has_children && (
              <RouterLink
                to={flattenToAppURL(node.href)}
                className="next-level"
                onClick={() => {
                  setShowRootNav(false);
                }}
              >
                <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
              </RouterLink>
            )}
          </>
        ) : (
          <UniversalLink href={flattenToAppURL(node.href)}>
            {node.title}
          </UniversalLink>
        )}
        {(node.items?.length && (
          <List.List>
            {node.items.map((node) =>
              renderNode(node, level, setShowRootNav, toggleMobileMenu),
            )}
          </List.List>
        )) ||
          ''}
      </List.Content>
    </List.Item>
  );
}

/**
 * A navigation slot implementation, similar to the classic Plone navigation
 * portlet. It uses the same API, so the options are similar to
 * INavigationPortlet
 */
export function ContextNavigationComponent(props) {
  const {
    navigation = {},
    showRootNav,
    setShowRootNav,
    toggleMobileMenu,
  } = props;
  const intl = useIntl();
  const { items = [] } = navigation;
  // Test if we are in the lang nav root
  const showBackLink = props.location.pathname.split('/').length !== 2;
  return (
    (items.length && (
      <nav className="context-navigation">
        {!showRootNav && showBackLink && (
          <List>
            <List.Content className="go-back">
              <UniversalLink
                title={intl.formatMessage(messages.goBack)}
                href={getParentUrl(getParentUrl(items[0]['@id']))}
              >
                <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
                {intl.formatMessage(messages.goBack)}
              </UniversalLink>
            </List.Content>
          </List>
        )}
        <List>
          {items.map((node) =>
            renderNode(node, 0, setShowRootNav, toggleMobileMenu),
          )}
        </List>
        <HeaderTop toggleMobileMenu={toggleMobileMenu} />
      </nav>
    )) ||
    null
  );
}

ContextNavigationComponent.propTypes = {
  /**
   * Navigation tree returned from @contextnavigation restapi endpoint
   */
  navigation: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    has_custom_name: PropTypes.bool,
    title: PropTypes.string,
  }),
};

export default compose(
  withRouter,
  withContentNavigation,
)(ContextNavigationComponent);
