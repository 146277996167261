import React from 'react';

import './styles.less';

const View = (props) => {
  const { data } = props;

  return (
    <div className="block quote">
      {data.text && <h4 className="quote-text">{data.text}</h4>}
      {data.name && <h5 className="quote-name">{data.name}</h5>}
      <div className="circle"></div>
    </div>
  );
};

export default View;
