import React from 'react';
import Researchlines from './researchlines';

const View = (props) => {
  const { data } = props;
  const researchlines = data.researchlines;
  const showname = data.showname;

  return (
    <>
      {data.title && <h2>{data.title}</h2>}
      {data.description && <h3>{data.description}</h3>}
      <Researchlines researchlines={researchlines} showname={showname} />
    </>
  );
};

export default View;
