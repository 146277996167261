import { FormattedDate } from '@plone/volto/components';

const ConditionalDate = ({ item }) => {
  let date = null;
  if (item?.['@type'] === 'Event') {
    date = item.start;
  } else {
    date = item.review_state === 'published' ? item.effective : item.created;
  }
  if (!date) return null;
  return (
    <FormattedDate
      format={{
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }}
      date={date}
    />
  );
};
export default ConditionalDate;
