import { addStyling } from '@plone/volto/helpers/Extensions/withBlockSchemaEnhancer';
import { defineMessages } from 'react-intl';
import config from '@plone/volto/registry';

const messages = defineMessages({
  backgroundColor: {
    id: 'Background color',
    defaultMessage: 'Background color',
  },
  fullWidth: {
    id: 'Full width background',
    defaultMessage: 'Full width background',
  },
});

export const defaultStylingSchema = ({ schema, formData, intl }) => {
  const BG_COLORS = [
    { name: 'transparent', label: 'Transparent' },
    { name: 'azure', label: 'Azure' },
    { name: 'blue', label: 'Blue' },
    { name: 'lightgray', label: 'Light Gray' },
    { name: 'darkgray', label: 'Dark Gray' },
  ];

  const BG_COLORS_TEASER = [
    { name: 'transparent', label: 'Transparent' },
    { name: 'lightblue', label: 'Light Blue' },
    { name: 'lightblue2', label: 'Light Blue Secondary' },
    { name: 'lightgreen', label: 'Light Green' },
    { name: 'lightred', label: 'Light Red' },
    { name: 'lightorange', label: 'Light Orange' },
  ];

  const colors =
    formData['@type'] === 'gridBlock' ? BG_COLORS_TEASER : BG_COLORS;

  const defaultBGColor =
    config.blocks?.blocksConfig?.[formData['@type']]?.defaultBGColor;

  addStyling({ schema, intl });

  schema.properties.styles.schema.fieldsets[0].fields.push('backgroundColor');
  schema.properties.styles.schema.fieldsets[0].fields.push('fullWidth');

  schema.properties.styles.schema.properties.backgroundColor = {
    widget: 'color_picker',
    title: intl.formatMessage(messages.backgroundColor),
    colors,
    default: defaultBGColor,
  };
  schema.properties.styles.schema.properties.fullWidth = {
    title: 'Full width background',
    type: 'boolean',
    default: false,
  };
  return schema;
};
