import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb, Container, Segment } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import './Breadcrumbs.less';
import NavHelper from 'volto-ipn/components/NavHelper/NavHelper';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

const BreadcrumbsComponent = ({ pathname }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const items = useSelector((state) => state.breadcrumbs.items, shallowEqual);
  const root = useSelector((state) => state.breadcrumbs.root);

  useEffect(() => {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(pathname))) {
      dispatch(getBreadcrumbs(getBaseUrl(pathname)));
    }
  }, [dispatch, pathname]);

  if (items.length < 2) {
    return null;
  } else {
    return (
      <>
        <Segment
          role="navigation"
          aria-label={intl.formatMessage(messages.breadcrumbs)}
          className="breadcrumbs"
          secondary
          vertical
        >
          <Container>
            <Breadcrumb>
              <Link
                to={root || '/'}
                className="section"
                title={intl.formatMessage(messages.home)}
              >
                {intl.formatMessage(messages.home)}
              </Link>
              {items.map((item, index, items) => [
                <Breadcrumb.Divider key={`divider-${item.url}`}>
                  <Icon
                    size="16px"
                    name={chevronSVG}
                    className={'linkprefix'}
                  />
                </Breadcrumb.Divider>,
                index < items.length - 1 ? (
                  <Link key={item.url} to={item.url} className="section">
                    {item.title}
                  </Link>
                ) : (
                  <Breadcrumb.Section key={item.url} active>
                    {item.title}
                  </Breadcrumb.Section>
                ),
              ])}
            </Breadcrumb>
          </Container>
        </Segment>
        <NavHelper />
      </>
    );
  }
};

BreadcrumbsComponent.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default BreadcrumbsComponent;
