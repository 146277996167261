import FooterMiddle from 'volto-ipn/components/Theme/Footer/FooterMiddle/FooterMiddle';
import FooterTop from 'volto-ipn/components/Theme/Footer/FooterTop/FooterTop';
import FooterBottom from 'volto-ipn/components/Theme/Footer/FooterBottom/FooterBottom';

function Footer() {
  return (
    <div className="footer" role="contentinfo">
      <FooterTop />
      <FooterMiddle />
      <FooterBottom />
    </div>
  );
}

export default Footer;
