import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  Rights: {
    id: 'Rights',
    defaultMessage: 'Rights',
  },
  Zoomable: {
    id: 'Zoomable',
    defaultMessage: 'Zoomable',
  },
});

export const imageBlockSchemaEnhancer = ({ formData, schema, intl }) => {
  if (formData.url) {
    schema.fieldsets[0].fields = [
      'alt',
      'description',
      'rights',
      'align',
      'size',
      'zoomable',
    ];
    schema.properties.description = {
      title: intl.formatMessage(messages.Description),
    };
    schema.properties.rights = {
      title: intl.formatMessage(messages.Rights),
    };
    schema.properties.zoomable = {
      title: intl.formatMessage(messages.Zoomable),
      type: 'boolean',
      default: true,
    };
  }
  // schema.properties.align.default = 'center';
  // schema.properties.align.actions = ['left', 'right', 'center', 'wide', 'full'];
  schema.properties.size.default = 'l';
  // schema.properties.size.disabled =
  //   formData.align === 'full' ||
  //   formData.align === 'wide' ||
  //   formData.align === 'center';

  return schema;
};
