import { researchlines_mapping } from './config';

const researchline_style = (item) => {
  let color = null;
  if (!item) {
    color = '#ccc';
  } else {
    color = researchlines_mapping[item]?.color;
  }
  return { backgroundColor: color };
};

export { researchline_style };
