import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import ConditionalDate from 'volto-ipn/components/ConditionalDate/ConditionalDate';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';

const DefaultTemplate = ({
  headlineTag,
  items,
  linkTitle,
  linkHref,
  isEditMode,
  showDate,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  const getTitleTag = (tag) => {
    const level = tag.slice(-1);
    if (/\d/.test(level)) {
      return `h${Number(level) + 1}`;
    } else {
      return 'h3';
    }
  };
  const TitleTag = headlineTag ? getTitleTag(headlineTag) : 'h3';

  const truncate = (str, maxlength) => {
    return str.length > maxlength ? str.slice(0, maxlength - 1) + '…' : str;
  };

  return (
    <>
      <div className="items">
        {items.map((item) => (
          <div className="listing-item" key={item['@id']}>
            <ConditionalLink item={item} condition={!isEditMode}>
              <div className="listing-body">
                {showDate && <ConditionalDate item={item} />}
                <TitleTag>{item.title || item.id}</TitleTag>
                <p>{truncate(item.description, 300)}</p>
              </div>
            </ConditionalLink>
          </div>
        ))}
      </div>

      {link && (
        <div className="footer">
          <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
          {link}
        </div>
      )}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
