import { BodyClass } from '@plone/volto/helpers';
import { FormattedMessage } from 'react-intl';
import { Container } from 'semantic-ui-react';
import { withServerErrorCode } from '@plone/volto/helpers/Utils/Utils';
import { UniversalLink } from '@plone/volto/components';
import { useSelector } from 'react-redux';
import notfound from './notfound.png';

/**
 * Not found function.
 * @function NotFound
 * @returns {string} Markup of the not found page.
 */
const NotFound = () => {
  const lang = useSelector((state) => state.intl.locale);
  const contact_link =
    lang === 'de'
      ? '/de/das-ipn/ueber-uns/kontakt'
      : '/en/the-ipn/about-us/contact';
  const frontpage_link = lang === 'de' ? '/de' : '/en';
  const theipn_link = lang === 'de' ? '/de/das-ipn' : '/en/the-ipn';
  const research_link = lang === 'de' ? '/de/forschen' : '/en/research';
  const society_link =
    lang === 'de' ? '/de/fuer-die-gesellschaft' : '/en/for-the-society';
  const search_link =
    lang === 'de' ? '/de/advanced-search' : '/en/advanced-search';

  return (
    <Container className="view-wrapper">
      <BodyClass className="page-not-found" />
      <div class="block image align center">
        <figure class="figure center large">
          <img src={notfound} loading="lazy" decoding="async" alt="" />
        </figure>
      </div>
      <h1>
        <FormattedMessage id="not_found_title" />
      </h1>
      <p className="description">
        <FormattedMessage id="not_found_description" />
      </p>
      <ul>
        <li>
          <UniversalLink href={frontpage_link}>
            <FormattedMessage id="not_found_frontpage" />
          </UniversalLink>
          : <FormattedMessage id="not_found_frontpage_text" />
        </li>
        <li>
          <UniversalLink href={theipn_link}>
            <FormattedMessage id="not_found_theipn" />
          </UniversalLink>
          : <FormattedMessage id="not_found_theipn_text" />
        </li>
        <li>
          <UniversalLink href={research_link}>
            <FormattedMessage id="not_found_research" />
          </UniversalLink>
          : <FormattedMessage id="not_found_research_text" />
        </li>
        <li>
          <UniversalLink href={society_link}>
            <FormattedMessage id="not_found_society" />
          </UniversalLink>
          : <FormattedMessage id="not_found_society_text" />
        </li>
      </ul>
      <FormattedMessage
        id="not_found_footer"
        defaultMessage="not_found_footer"
        values={{
          search_function: (
            <UniversalLink href={search_link}>
              <FormattedMessage id="not_found_search" />
            </UniversalLink>
          ),
          contact_form: (
            <UniversalLink href={contact_link}>
              <FormattedMessage id="not_found_contact" />
            </UniversalLink>
          ),
        }}
      />
    </Container>
  );
};

export default withServerErrorCode(404)(NotFound);
