import React from 'react';
import PropTypes from 'prop-types';
import {
  Component,
  ConditionalLink,
  UniversalLink,
} from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';
import { Grid } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import cx from 'classnames';
import { researchline_style } from 'volto-ipn/components/Blocks/researchlines/utils';
import { project_height } from 'volto-ipn/components/Blocks/Teaser/utils';

const ProjectListingBlockTemplate = ({
  items,
  linkTitle,
  linkHref,
  isEditMode,
}) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  if (isInternalURL(href)) {
    link = (
      <UniversalLink href={flattenToAppURL(href)}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />
        {linkTitle || href}
      </UniversalLink>
    );
  } else if (href) {
    link = (
      <UniversalLink href={href}>
        <Icon size="16px" name={chevronSVG} className={'linkprefix'} />{' '}
        {linkTitle || href}
      </UniversalLink>
    );
  }

  return (
    <>
      <Grid className="projects" columns={4}>
        {items.map((item) => (
          <Grid.Column key={item['@id']}>
            <div className={cx('project', item.researchlines)}>
              <ConditionalLink
                to={item?.['@id'] + '?show_navhelper=1'}
                condition={!isEditMode}
              >
                <div
                  className={cx(
                    'researchline-content',
                    project_height(item.researchlines?.length),
                  )}
                >
                  {item.image_field ? (
                    <Component
                      componentName="PreviewImage"
                      item={item}
                      alt="Project Logo"
                      className="ui middle aligned image"
                    />
                  ) : (
                    <div className="missing-project-logo"></div>
                  )}
                  <div className="listing-body">
                    <h3 className="acronym">{item.acronym}</h3>
                    <h3 className="project-title">{item.project_title}</h3>
                  </div>
                </div>
              </ConditionalLink>
              {item.researchlines && (
                <div className={`researchline-footer`}>
                  {item.researchlines.map((line, index) => (
                    <div
                      key={index}
                      className="researchline-line"
                      style={researchline_style(line)}
                    ></div>
                  ))}
                </div>
              )}
            </div>
          </Grid.Column>
        ))}
      </Grid>
      {link && <div className="linkMore">{link}</div>}
    </>
  );
};
ProjectListingBlockTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default ProjectListingBlockTemplate;
