import { defineMessages } from 'react-intl';

const messages = defineMessages({
  AltText: {
    id: 'Alt text',
    defaultMessage: 'Alt text',
  },
  AltTextHint: {
    id: 'Alt text hint',
    defaultMessage: 'Leave empty if the image is purely decorative.',
  },
  AltTextHintLinkText: {
    id: 'Alt text hint link text',
    defaultMessage: 'Describe the purpose of the image.',
  },
  openLinkInNewTab: {
    id: 'Open in a new tab',
    defaultMessage: 'Open in a new tab',
  },
});

export const LargeHeroSchema = (props) => {
  return {
    required: [],
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: [
          'teaser',
          'title',
          'description',
          'image',
          'alt',
          'copyright',
          'linkTitle',
          'linkHref',
        ],
      },
    ],
    properties: {
      teaser: {
        title: 'Teaser',
        widget: 'text',
      },
      title: {
        title: 'Title',
        widget: 'text',
      },
      description: {
        title: 'Description',
        widget: 'text',
      },
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        selectedItemAttrs: ['image_field', 'image_scales'],
      },
      alt: {
        title: props.intl.formatMessage(messages.AltText),
        description: (
          <>
            <a
              href="https://www.w3.org/WAI/tutorials/images/decision-tree/"
              title={props.intl.formatMessage(messages.openLinkInNewTab)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {props.intl.formatMessage(messages.AltTextHintLinkText)}
            </a>{' '}
            {props.intl.formatMessage(messages.AltTextHint)}
          </>
        ),
      },
      copyright: {
        title: 'Foto/Copyright',
        widget: 'text',
      },
      linkTitle: {
        title: 'Button Title',
        widget: 'text',
      },
      linkHref: {
        title: 'Button link',
        widget: 'object_browser',
        mode: 'link',
        allowExternals: true,
      },
    },
  };
};

export default LargeHeroSchema;
