import React from 'react';
import PropTypes from 'prop-types';
import ButtonMore from '../../../components/ButtonMore';
import { flattenToAppURL } from '@plone/volto/helpers';
import cx from 'classnames';

const Template = (props) => {
  const { className, data } = props;

  return (
    <div className={cx('block hero hero-left full', className)}>
      <div className="block-inner-wrapper full-width">
        {data.image && data.image[0] && (
          <img
            src={`${flattenToAppURL(data.image[0]['@id'])}/@@images/image`}
            alt={data.alt}
            className="hero-image-left"
            loading="lazy"
          />
        )}
        <div className="hero-body">
          <div className={`hero-text`}>
            {data.teaser && <h5>{data.teaser}</h5>}
            {data.title && <h3>{data.title}</h3>}
            {data.description && <p>{data.description}</p>}
            <ButtonMore data={data} />
            {data.copyright && (
              <p className="copyright">Foto/Copyright: {data.copyright}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Template.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Template;
