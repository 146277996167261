import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '@plone/volto/components';
import OutsideClickHandler from 'react-outside-click-handler';
import xmarkSVG from 'volto-ipn/icons/xmark.svg';
import { defineMessages, useIntl } from 'react-intl';
import { Segment, Container, Grid, Button } from 'semantic-ui-react';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import cx from 'classnames';
import {
    flattenToAppURL,
} from '@plone/volto/helpers';
import './dropdownmenu.less';

const messages = defineMessages({
    unknownBlock: {
        id: 'Unknown Block',
        defaultMessage: 'Unknown Block {block}',
    },
    closeMenu: {
        id: 'dropdownmenu-close-menu-button',
        defaultMessage: 'Close menu',
    },
});

const DropdownMenu = ({ menu, open = false, closeMenu }) => {
    const intl = useIntl();
    const navItemWidth = 3;

    const lastLinkEventListener = (e) => {
        if (e.code === 'Tab') {
            document
                .querySelector(
                    '.dropdown-menu-wrapper.open button.dropdown-close-button',
                )
                .focus();
        }
    };

    useEffect(() => {
        const links = document.querySelectorAll('.dropdown-menu-wrapper.open a');
        const lastLink = links[links.length - 1];
        if (lastLink) {
            lastLink.addEventListener('keydown', lastLinkEventListener);
        }
    });

    return (
        <div
            className={cx('dropdown-menu-wrapper', {
                open,
                'multi-navigation-root': menu.navigationRoot?.length > 1,
            })}
            aria-hidden={!open}
            tabIndex={-1}
            role="menu"
        >
          <OutsideClickHandler
            onOutsideClick={() => {
              closeMenu()
            }}
          >
            <div className="dropdown-menu-inner">
                <Segment>
                    <div className="dropdownmenu-close-button-wrapper">
                        <Button
                            className="dropdown-close-button"
                            onClick={closeMenu}
                            title={intl.formatMessage(messages.closeMenu)}
                            icon="times"
                            basic
                            size="mini"
                        />
                    </div>
                    <Grid container>
                        {menu.navigationRoot?.map((navRoot) => (
                            <>
                                <Container>
                                    <h2>
                                        <NavLink to={flattenToAppURL(navRoot['@id'])}>
                                            <span>{navRoot.title}</span>
                                        </NavLink>
                                    </h2>
                                    <div
                                        className="dropdownmenu-close-button-wrapper"
                                        onClick={closeMenu}
                                    >
                                        <Icon
                                            name={xmarkSVG}
                                            color="#003F7D"
                                            className="dropdown-close-button"
                                            title={intl.formatMessage(messages.closeMenu)}
                                            size="22px"
                                        />
                                    </div>
                                </Container>
                                {navRoot.items?.length > 0 &&
                                    navRoot.items?.map((navItem, idx) => (
                                        <>
                                            <Grid.Column width={navItemWidth} key={navRoot['@id']}>
                                                <div>
                                                    <div key={navRoot['@id'] + idx}>
                                                        <NavLink
                                                            className="subheading-item"
                                                            to={flattenToAppURL(navItem['@id'])}
                                                            onClick={closeMenu}
                                                        >
                                                            <h3>{navItem.title}</h3>
                                                        </NavLink>

                                                        {navItem.items?.length > 0 &&
                                                            navItem.items?.map((subNavItem, idx) => (
                                                                <li
                                                                    className="subnav-item"
                                                                    key={navItem['@id'] + idx}
                                                                >
                                                                    <NavLink
                                                                        to={flattenToAppURL(subNavItem['@id'])}
                                                                        onClick={closeMenu}
                                                                    >
                                                                        <span>{subNavItem.title}</span>
                                                                    </NavLink>
                                                                </li>
                                                            ))}
                                                    </div>
                                                </div>
                                            </Grid.Column>
                                        </>
                                    ))}
                            </>
                        ))}
                    </Grid>
                    {menu.showMoreLink?.length > 0 && menu.showMoreText?.length > 0 && (
                        <Container className="dropdownmenu-footer">
                            <NavLink
                                to={flattenToAppURL(menu.showMoreLink[0]['@id']) ?? '#'}
                                onClick={closeMenu}
                            >
                                <span>{menu.showMoreText}</span>
                                <SemanticIcon name="arrow right" />
                            </NavLink>
                        </Container>
                    )}
                </Segment>
            </div>
          </OutsideClickHandler>
        </div>
    );
};

export default DropdownMenu;
