import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import imageBlockSVG from '@plone/volto/components/manage/Blocks/Image/block-image.svg';
import { isInternalURL } from '@plone/volto/helpers';
import { MaybeWrap } from '@plone/volto/components';
import { UniversalLink } from '@plone/volto/components';
import cx from 'classnames';
import config from '@plone/volto/registry';
import chevronSVG from 'volto-ipn/icons/chevron-right.svg';
import { Icon } from '@plone/volto/components';
import Caption from 'volto-ipn/components/Caption/Caption';

const messages = defineMessages({
  PleaseChooseContent: {
    id: 'Please choose an existing content as source for this element',
    defaultMessage:
      'Please choose an existing content as source for this element',
  },
});

const TeaserDefaultTemplate = (props) => {
  const { className, data, isEditMode } = props;
  const intl = useIntl();
  const href = data.href?.[0];
  const image = data.preview_image?.[0];
  const HeadlineTag = data?.headlineTag || 'h2';

  const Image = config.getComponent('Image').component;
  const { openExternalLinkInNewTab } = config.settings;
  const target_url =
    href && (data?.externalURL ? data?.externalURL : href && href?.['@id']);

  return (
    <div className={cx('block teaser', className)}>
      <>
        {!href && isEditMode && (
          <Message>
            <div className="teaser-item placeholder">
              <img src={imageBlockSVG} alt="" />
              <p>{intl.formatMessage(messages.PleaseChooseContent)}</p>
            </div>
          </Message>
        )}
        {target_url && (
          <MaybeWrap
            condition={!isEditMode}
            as={UniversalLink}
            href={target_url}
            target={
              data.openLinkInNewTab ||
              (openExternalLinkInNewTab && !isInternalURL(target_url))
                ? '_blank'
                : null
            }
          >
            <div className="teaser-item default">
              {!data.hideImage &&
                (href.hasPreviewImage || href.image_field || image) && (
                  <div className="image-wrapper">
                    <Image
                      item={image || href}
                      imageField={image ? image.image_field : href.image_field}
                      alt={data.alt || href.alttext || ''}
                      loading="lazy"
                      responsive={true}
                    />
                    {data?.preview_image && (
                      <Caption credit={data?.preview_image[0]?.rights} />
                    )}
                  </div>
                )}
              <div className="content">
                {data?.head_title && (
                  <div className="headline">{data.head_title}</div>
                )}
                <HeadlineTag>{data?.title}</HeadlineTag>
                {!data.hide_description && <p>{data?.description}</p>}
                {data.footer && (
                  <p className="teaserfooter">
                    <Icon
                      size="16px"
                      name={chevronSVG}
                      className={'linkprefix'}
                    />
                    {data?.footer}
                  </p>
                )}
              </div>
            </div>
          </MaybeWrap>
        )}
      </>
    </div>
  );
};

TeaserDefaultTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default TeaserDefaultTemplate;
